import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { InputRequest } from '@dto/commons/input';

export interface Question {
  title: string;
  questionHtml: string;
  submitText: string;
  cancelText: string;
  questionClass: 'btn-primary' | 'btn-green' | 'btn-danger' | '';

  input?: InputRequest
}

@Injectable({ providedIn: 'root' })
export class QuestionService {

  question = new Subject<Question>();
  answer = new Subject<any | boolean>();

  ask<T = boolean>(
    title: string,
    questionHtml: string,
    submitText?: string,
    cancelText?: string,
    questionClass?: 'btn-primary' | 'btn-green' | 'btn-danger' | '',
    input?: InputRequest
  ): Promise<boolean | T> {
    this.question.next({
      title,
      questionHtml,
      submitText: submitText ?? 'Подтвердить',
      cancelText: cancelText ?? 'Отмена',
      questionClass: questionClass || 'btn-primary',
      input
    });
    return new Promise<T>(resolve => {
      const subscription = this.answer.subscribe(next => {
        subscription.unsubscribe();
        resolve(next);
      });
    });
  }
}
